import { Link, Button, navigate } from 'gatsby';
import React, {useState} from 'react'
import Layout from '../components/Layout'
import {generateCookie, retrieve_cookie} from '../components/cookies/tycoon-cookies'


function tycoon() {
    const cookieName = "Tycoon202307";
    var cookieValue ="";
    
    const sendNotification = () => {
        const formUrl = "https://form.itiqpro.com/tycoon"
        if (typeof document !== "undefined") {
            let data = new FormData();
            data.append("name", cookieName)
            data.append("value", cookieValue)
            fetch(formUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: data,
                mode: 'no-cors',
            });
            navigate("/")
        }
    }
    
    if (typeof document !== "undefined") {
        var checkCookie = retrieve_cookie(cookieName)
        //Check if cookie already exists to avoid sending the same notification
        if(checkCookie === "undefined" || checkCookie === null || checkCookie === "")
        {
            generateCookie(cookieName);
            cookieValue = retrieve_cookie(cookieName);
            sendNotification();
        }
        else{
            navigate("/")
        }
        
    }

    

  
    

    return(
        <section className="hero is-fullheight">
        <div className="hero-body">
            <div className="container has-text-centered is-size-8">
            <h1
                className="has-text-vivid-red has-text-weight-bold"
                style={{padding: '1rem'}}
            >
            </h1>
            </div>
        </div>
        </section>
    )
}

export default tycoon
  
